import { Injectable } from '@angular/core';
import { SessionUser, UserClientVm, DeleteUserByFirm, ResetPasswordData, LeaveFirm } from '../../store/user/user.interface';
import { HttpBaseService } from './http-base.service';
import { Store } from '@ngrx/store';
import { getSessionUser } from '../../store/user/user.selector';
import { lastValueFrom } from 'rxjs';
import { Reviewer } from '../../modules/client/dashboard/people/people.interface';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  
  userObject!: UserClientVm;

  constructor(private http:HttpBaseService, private store: Store<SessionUser>) { 
    this.store.select(getSessionUser).subscribe((user:UserClientVm)=>{
      this.userObject = user;
    });
  }

  getSessionUser() {
    return this.userObject; 
  }

  getUserById(id:string){
    return lastValueFrom(this.http.get(`users/${id}`));
  }

  approveUser(reviewerId: string) {
    const url = `users/${reviewerId}/approve`
    return this.http.put(url);
  }
  manualRejectEducation(reviewerId: string,payload:any) {
    const url = `users/${reviewerId}/rejectBarEducationManual`
    return lastValueFrom(this.http.post(url,payload));
  }
  manualApproveEducation(reviewerId: string,payload:any) {
    const url = `users/${reviewerId}/approveBarEducationManual`
    return lastValueFrom(this.http.post(url,payload));
  }

  manualRejectCredential(reviewerId: string,payload:any) {
    const url = `users/${reviewerId}/rejectCredentialManual`
    return lastValueFrom(this.http.post(url,payload));
  }
  manualApproveCredential(reviewerId: string,payload:any) {
    const url = `users/${reviewerId}/approveCredentialManual`
    return lastValueFrom(this.http.post(url,payload));
  }

  deleteUserByFirm(reviewerId: string, data: DeleteUserByFirm) {
    const url = `users/deleteReviewerByFirm/${reviewerId}`
    return this.http.delete(url, data);
  }

  leaveFirmPermenant(reviewerId: string, data: LeaveFirm) {
    const url = `users/deleteReviewerByFirm/${reviewerId}`
    return this.http.delete(url, data);
  }

  restoreUserByFirm(reviewerId: string) {
    const url = `users/restoreArchivedReviewerByFirm/${reviewerId}`
    return lastValueFrom(this.http.put(url));
  }

  getUserId(){
    return this.userObject._id;
  }

  getUserRole(){
    return this.userObject.role;
  }
  
  getPositions(){
    return this.http.get('positions');
  }

  getUserFirmId(){
    return (this.userObject?.firm?._id) || '';
  }

  resetPassword (data:ResetPasswordData) {
    return this.http.put('auth/resetPassword', data);
  };

  verifyPhone(body: any ) {
    return this.http.put('users/verifyPhone', body);
  }

  getAbdetails(reviewerId: string) {
    return this.http.get(`users/${reviewerId}/abDetails`);
  }

  invokeBackgroundCheck(reviewerId:string, body:any) {
    return this.http.post(`users/${reviewerId}/manualCheck`,  body);
  };

  invokeCriminalCheck(reviewerId:string, body:any) {
    return this.http.post(`users/${reviewerId}/criminalCheck`,  body);
  };

  invokeEducationBackgroundCheck(reviewerId:string, body:any) {
    return this.http.post(`users/${reviewerId}/approveEducation`, body);
  };

  notInterestedConfirmation(hash: string, payload: {firm: string}) {
    return lastValueFrom(this.http.delete(`auth/deleteReviewerByReviewerFromFirm/${hash}`, payload));
  };

  getNotificationPreference() {
    return this.http.get('users/getNotificationPreference');
  }

  getUserCurrentTenant(user: Reviewer){
    return user.tenants.find((tenant: any) => {
      const isFirmString = typeof tenant.firm === 'string';
      return isFirmString ? tenant.firm : tenant.firm._id === this.userObject.firm._id
    })
  }
}
